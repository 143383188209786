import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import RequestAccess from "./request-access"

const Header = ({ siteTitle, titleImage, modalHandler }) => (
    <Navbar bg="white" expand="lg" fixed="top" style={{maxWidth: 1240, margin: `0 auto`}}>
      <Navbar.Brand href="/#main" style={{width: 200}}>
        <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          `}
          render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} fadeIn={false}/>}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <NavDropdown title="Solutions" id="basic-nav-dropdown">
            <NavDropdown.Item href="#ivr-deflection">IVR Deflection</NavDropdown.Item>
            <NavDropdown.Item href="#efficiency">Conversational Commerce</NavDropdown.Item>
            <NavDropdown.Item href="#integrations">Universal Messaging Platform</NavDropdown.Item>
            <NavDropdown.Item href="#livechat">Live Chat</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="https://app.babblestack.com/" style={{color: `black`}}><strong>Log in</strong></Nav.Link>
          <RequestAccess style={{marginLeft: 10}} onClick={modalHandler}/>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
