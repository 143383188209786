import React from "react"
import { Button } from "react-bootstrap"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const RequestAccess = ({style, onClick}) => (
  <Button variant="success"
    style = {{
      fontFamily: `Raleway`,
      fontWeight: `bold`,
      ...style
    }}
    onClick = {() => onClick("request")}
  >
    Schedule a Demo
  </Button>
)
export default RequestAccess
