/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "./header"
import "./layout.css"
import 'bootstrap/dist/css/bootstrap.min.css';

function Layout({ children, modalHandler }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Header siteTitle={data.site.siteMetadata.title} titleImage={data.logoImage} modalHandler={modalHandler}/>
          <Helmet>
            <script async="true">
              {`!function(e,t,n,o){var r,s,p,i=[],c=[];e[n]={init:function(){r=arguments;var e={then:function(t){return c.push({type:"t",next:t}),e},catch:function(t){return c.push({type:"c",next:t}),e}};return e},on:function(){i.push(arguments)},render:function(){s=arguments},destroy:function(){p=arguments}},e.__onWebMessengerHostReady__=function(t){if(delete e.__onWebMessengerHostReady__,e[n]=t,r)for(var o=t.init.apply(t,r),a=0;a<c.length;a++){var u=c[a];o="t"===u.type?o.then(u.next):o.catch(u.next)}for(s&&t.render.apply(t,s),p&&t.destroy.apply(t,p),a=0;a<i.length;a++)t.on.apply(t,i[a])};var a=new XMLHttpRequest;a.addEventListener("load",function(){try{if(("string"==typeof this.response?JSON.parse(this.response):this.response).url){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.async=!0,n.src="https://babblestack.com/4.17.15.min.js",e.parentNode.insertBefore(n,e)}}catch(e){}}),a.open("GET","https://5d17f0426403b10010101842.webloader.smooch.io/",!0),a.responseType="json",a.send()}(window,document,"Smooch"); Smooch.init({ appId: '5d17f0426403b10010101842' });`}
            </script>
          </Helmet>
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 1240,
              padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 0
            }}
          >
            <main>{children}</main>
          </div>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
